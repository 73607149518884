import React, { FC, memo, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import { EducationSlice } from "../../../store/reducers/education/EducationSlice";

interface ICertificationEvent {
  id: number;
  dateFrom: Date;
  dateTo: Date;
  time: string;
  program: string;
  location: string;
  completed: boolean;
  lastCertification: Date;
}

interface ITableCertification {
  date: Date;
}

const TableCertification: FC<ITableCertification> = ({ date }) => {
  const dispatch = useAppDispatch();
  const { certificationEvents } = useAppSelector(state => state.EducationReducer);

  const filteredEvents = certificationEvents.filter(event =>
      moment(event.dateFrom).isSame(date, 'month') &&
      moment(event.dateFrom).isSame(date, 'year')
  );

  const toggleCompleted = (eventId: number) => {
    dispatch(EducationSlice.actions.toggleCertificationCompleted(eventId));
  };

  const columns = [
    {
      name: <span className='font-weight-bold accessibility_size_table'>Дата С</span>,
      selector: (row: ICertificationEvent) => moment(row.dateFrom).format('DD.MM.YYYY'),
      cell: (row: ICertificationEvent) => (
          <span className="accessibility_size_table">{moment(row.dateFrom).format('DD.MM.YYYY')}</span>
      ),
      sortable: true,
      style: { fontSize: '14px' },
      wrap: true,
    },
    {
      name: <span className='font-weight-bold accessibility_size_table'>Дата ПО</span>,
      selector: (row: ICertificationEvent) => moment(row.dateTo).format('DD.MM.YYYY'),
      cell: (row: ICertificationEvent) => (
          <span className="accessibility_size_table">{moment(row.dateTo).format('DD.MM.YYYY')}</span>
      ),
      sortable: true,
      style: { fontSize: '14px' },
      wrap: true,
    },
    {
      name: <span className='font-weight-bold accessibility_size_table'>Время</span>,
      selector: (row: ICertificationEvent) => row.time,
      cell: (row: ICertificationEvent) => (
          <span className="accessibility_size_table">{row.time}</span>
      ),
      sortable: true,
      style: { fontSize: '14px' },
      wrap: true,
    },
    {
      name: <span className='font-weight-bold accessibility_size_table'>Программа</span>,
      selector: (row: ICertificationEvent) => row.program,
      cell: (row: ICertificationEvent) => (
          <span className="accessibility_size_table">{row.program}</span>
      ),
      sortable: true,
      style: { fontSize: '14px' },
      wrap: true,
    },
    {
      name: <span className='font-weight-bold accessibility_size_table'>Место</span>,
      selector: (row: ICertificationEvent) => row.location,
      cell: (row: ICertificationEvent) => (
          <span className="accessibility_size_table">{row.location}</span>
      ),
      sortable: true,
      style: { fontSize: '14px' },
      wrap: true,
    },
    // {
    //   name: <span className='font-weight-bold accessibility_size_table'>Дата предыдущей аттестации</span>,
    //   selector: (row: ICertificationEvent) => moment(row.lastCertification).format('DD.MM.YYYY'),
    //   cell: (row: ICertificationEvent) => (
    //       <span className="accessibility_size_table">{moment(row.lastCertification).format('DD.MM.YYYY')}</span>
    //   ),
    //   sortable: true,
    //   style: { fontSize: '14px' },
    //   wrap: true,
    // },
    {
      name: <span className='font-weight-bold accessibility_size_table'>Результат</span>,
      cell: (row: ICertificationEvent) => (
          <input
              type="checkbox"
              checked={row.completed}
              onChange={() => toggleCompleted(row.id)}
              className="form-check-input"
          />
      ),
      center: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: ICertificationEvent) => row.completed,
      style: {
        backgroundColor: '#e9ecef',
      },
    },
  ];

  return (
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Тестирование</h4>
        </CardHeader>
        <CardBody className="py-0">
          <DataTable
              columns={columns}
              data={filteredEvents}
              noDataComponent={'Нет данных о тестировании за этот месяц'}
              pagination
              paginationRowsPerPageOptions={[5, 10, 20, 30]}
              paginationComponentOptions={{
                rowsPerPageText: 'Видно:',
                rangeSeparatorText: 'из',
              }}
              defaultSortFieldId="dateFrom"
              defaultSortAsc={false}
              highlightOnHover
              customStyles={{
                rows: {
                  highlightOnHoverStyle: {
                    backgroundColor: "#ffebb5",
                  },
                },
              }}
              conditionalRowStyles={conditionalRowStyles}
          />
        </CardBody>
      </Card>
  );
};

export default memo(TableCertification);