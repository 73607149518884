import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../hooks/redux";
import { ApexOptions } from "apexcharts";
import moment from "moment";

interface ICertificationDonutChart {
    date: Date;
}

const CertificationDonutChart: React.FC<ICertificationDonutChart> = ({ date }) => {
    const { certificationEvents } = useAppSelector(state => state.EducationReducer);

    // Фильтр по выбранному месяцу на основе dateFrom
    const filteredEvents = certificationEvents.filter(event =>
        moment(event.dateFrom).isSame(date, 'month') &&
        moment(event.dateFrom).isSame(date, 'year')
    );
    const totalCertifications = filteredEvents.length;
    const completedCertifications = filteredEvents.filter(event => event.completed).length;

    const series = [completedCertifications, totalCertifications - completedCertifications];
    const options: ApexOptions = {
        labels: ["Пройдено", "Не пройдено"],
        chart: {
            type: "donut" as const,
            height: 230,
        },
        plotOptions: {
            pie: {
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'bottom',
        },
        stroke: {
            lineCap: "round",
            width: 0,
        },
        colors: ['#34c38f', '#f46a6a'],
    };

    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Тестирование</h4>
            </CardHeader>
            <CardBody>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                    height={230}
                    className="apex-charts"
                />
                <div className="mt-3">
                    <div className="d-flex justify-content-center align-items-center mb-4">
                        <div>
                            <p className="text-muted mb-0">Всего тестирований</p>
                        </div>
                        <h2 className="mx-3 ff-secondary mb-0">{totalCertifications}</h2>
                    </div>
                    <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                        <p className="fw-medium mb-0">Пройдено</p>
                        <div>
                            <span className="text-muted pe-5">{completedCertifications}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                        <p className="fw-medium mb-0">Не пройдено</p>
                        <div>
                            <span className="text-muted pe-5">{totalCertifications - completedCertifications}</span>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default CertificationDonutChart;