import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

interface ITrainingEvent {
    id: number;
    dateFrom: Date;
    dateTo: Date;
    type: 'Онлайн' | 'Офлайн';
    time?: string;
    topic: string;
    location: string;
    hours: number;
    completed: boolean;
}

interface ICertificationEvent {
    id: number;
    dateFrom: Date;
    dateTo: Date;
    time: string;
    program: string;
    location: string;
    completed: boolean;
    lastCertification: Date;
}

interface EducationState {
    selectedDate: Date;
    isLoading: boolean;
    error: string | null;
    trainingEvents: ITrainingEvent[];
    certificationEvents: ICertificationEvent[];
}

const initialState: EducationState = {
    selectedDate: moment().startOf('month').toDate(),
    isLoading: false,
    error: null,
    trainingEvents: [
        { id: 1, dateFrom: new Date(2025, 3, 10), dateTo: new Date(2025, 3, 12), type: 'Онлайн', time: "10:00", topic: "Тест", location: "Кабинет 34", hours: 2, completed: false },
        { id: 2, dateFrom: new Date(2025, 3, 14), dateTo: new Date(2025, 3, 15), type: 'Офлайн', time: "10:00", topic: "Тест React", location: "Офис 12", hours: 2, completed: false },
        { id: 3, dateFrom: new Date(2025, 3, 12), dateTo: new Date(2025, 3, 13), type: 'Онлайн', time: "10:00", topic: "Тест TypeScript", location: "Офис", hours: 5, completed: false },
        { id: 4, dateFrom: new Date(2025, 3, 11), dateTo: new Date(2025, 3, 11), type: 'Офлайн', time: "10:00", topic: "Node.js", location: "Офис 1", hours: 0, completed: false },
        { id: 5, dateFrom: new Date(2025, 3, 14), dateTo: new Date(2025, 3, 16), type: 'Онлайн', time: "10:00", topic: "Тест 2", location: "Офис 2", hours: 3, completed: false },
        { id: 6, dateFrom: new Date(2025, 2, 2), dateTo: new Date(2025, 2, 3), type: 'Офлайн', time: "10:00", topic: "Тест DevOps", location: "Офис 3", hours: 7, completed: false },
        { id: 7, dateFrom: new Date(), dateTo: new Date(), type: 'Онлайн', time: "10:00", topic: "Тест", location: "Офис", hours: 2, completed: false },
    ],
    certificationEvents: [
        { id: 1, dateFrom: new Date(2025, 3, 20), dateTo: new Date(2025, 3, 21), program: "Сертификация React", location: "Офис 2", time: "14:00", lastCertification: new Date(2024, 2, 20), completed: false },
        { id: 2, dateFrom: new Date(2025, 3, 21), dateTo: new Date(2025, 3, 22), program: "Сертификация 2", location: "Офис 1", time: "14:00", lastCertification: new Date(2024, 2, 21), completed: false },
        { id: 3, dateFrom: new Date(2025, 3, 22), dateTo: new Date(2025, 3, 23), program: "Сертификация TypeScript", location: "Офис 3", time: "15:00", lastCertification: new Date(2024, 1, 15), completed: false },
        { id: 4, dateFrom: new Date(2025, 3, 25), dateTo: new Date(2025, 3, 26), program: "Сертификация Node.js", location: "Офис 4", time: "09:30", lastCertification: new Date(2024, 0, 10), completed: false },
        { id: 5, dateFrom: new Date(2025, 3, 28), dateTo: new Date(2025, 3, 29), program: "Сертификация DevOps", location: "Офис 5", time: "11:00", lastCertification: new Date(2023, 11, 5), completed: false },
    ],
};

export const EducationSlice = createSlice({
    name: 'education',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<Date>) => {
            state.selectedDate = action.payload;
        },
        setTrainingEvents: (state, action: PayloadAction<ITrainingEvent[]>) => {
            state.trainingEvents = action.payload;
        },
        toggleTrainingCompleted: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const event = state.trainingEvents.find(event => event.id === id);
            if (event) {
                event.completed = !event.completed;
            }
        },
        setCertificationEvents: (state, action: PayloadAction<ICertificationEvent[]>) => {
            state.certificationEvents = action.payload;
        },
        toggleCertificationCompleted: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const event = state.certificationEvents.find(event => event.id === id);
            if (event) {
                event.completed = !event.completed;
            }
        },
    },
});

export const { setDate, setTrainingEvents, toggleTrainingCompleted, setCertificationEvents, toggleCertificationCompleted } = EducationSlice.actions;
export default EducationSlice.reducer;